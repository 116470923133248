import React, { Component } from 'react'
import HeroImage from '../components/heroImage'
import Fade from 'react-reveal/Fade'
import styled, { keyframes } from 'styled-components';

const animationTitle = keyframes`
  0% {
      opacity: 0;
      transform: translateY(-40px);
  }
  100% {
      opacity: 1;
      transform: translateY(0px);  
  }
`
const animationSubtitle = keyframes`
  0% {
      opacity: 0;
      transform: translateY(-20px);      
  }
  100% {
      opacity: 1;
      transform: translateY(0px);
  }
`

 const MainContainer = styled.div`
  width: 100%;
  left: 0;
  background-color: #fff;
  display: flex;
  align-items:center;
`
 const TextContainer = styled.div`
  width: 40%;
  padding: 5em 0em 0em 0em;
  margin: auto;
  align-items: flex;

  @media only screen and (min-width: 375px) and (max-width: 812px) {
   width: 80%;
   padding: 5em 0em 0em 0em;   
  }

`
 const Title = styled.div`
  width: 100%;
  font-weight: 600;
  font-size: 3em;
  line-height: 1.3;
  opacity: 0;
  margin-bottom: 0.2em;
  animation: ${animationTitle} 1.6s 0s forwards cubic-bezier(0.2, 0.8, 0.2, 1);

  @media only screen and (min-width: 375px) and (max-width: 812px) {
   width: 100%;
   font-size: 2.6em;
   line-height: 1.3;
  }
`
 const Subtitle = styled.div`
  width: 100%;
  font-weight: 600;
  font-size: 3em;
  line-height: 1.3;
  color: #A1A5AA;
  opacity: 0;
  animation: ${animationSubtitle} 1.6s 0s forwards cubic-bezier(0.2, 0.8, 0.2, 1);
  animation-delay: 0.2s;

  @media only screen and (min-width: 375px) and (max-width: 812px) {
   width: 100%;
   font-size: 2.6em;
   line-height: 1.3;
  }
`


const HeroCaseStudy = props => (
  <div>
      <MainContainer>
        <TextContainer>
          <Title>{props.title}</Title>
          <Subtitle>{props.subtitle}</Subtitle>
        </TextContainer>
      </MainContainer>
      <Fade bottom duration={1100} delay={400} distance="6em" ssrFadeout>
        
      </Fade>
  </div>
)

export default HeroCaseStudy