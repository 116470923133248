import React, { Component } from 'react'
import styled, { keyframes }  from 'styled-components'
import Link from 'gatsby-link'
import agorizeProductData from '../../agorizeProductData.json'
import HeroImage from '../components/heroImage'
import LargeImage from '../components/largeImage'
import HeroCaseStudy from '../components/heroCaseStudy'
import TextBlockCentered from '../components/textBlockCentered'
import TextBlockRight from '../components/textBlockRight'
import TextBlock2col from '../components/textBlock-2col.js'
import IntroCaseStudy2 from '../components/introCaseStudy-2col'
import IntroCaseStudy3 from '../components/introCaseStudy-3.js'
import BigTitleCaseStudy from '../components/bigTitleCaseStudy.js'
import FooterCaseStudyBack from '../components/footerCaseStudyBack.js'
import Layout from "../components/layout";


const slideUp = keyframes`
  0% {
      transform: translateY(-50px)
  }
  100% {
      transform: translateY(0px)
  }
`

const SectionHeroCaseStudy = styled.div`
width: 100%;

  & before {
  content:'';
  height: 0vh;
  width: 100vw;
  background-color: #0065ff;
  position:absolute;
  bottom:0;
  z-index: 100;  
  animation: ${slideUp} 1s forwards cubic-bezier(0.2, 0.8, 0.2, 1.0);
  }
`
const SectionIntroCaseStudy = styled.div`
width: 100%;
`
const SectionTextBlockCentered = styled.div`
width: 100%;
`
 const FooterContainer = styled.div`
  width: 80%;
  margin: 8em auto 4em auto; 
  font-weight: 700;
`
 const LeftContainer = styled.div`
  width: 50%;
  display: inline-block;
`
 const RightContainer = styled.div`
  width: 50%;
  text-align: right;
  display: inline-block;
`
 const PreviousLink = styled.div`
  width: 50%;
  text-align: left;
  display: flex;
  align-items: center;
`
 const NextLink = styled.div`
  width: 50%;
  text-align: right;
  display: inline-block;
  align-items: center;
`


export default class Agorize extends Component {
	render () {
		return (
		<Layout>
			<div>
				<SectionHeroCaseStudy>
					{agorizeProductData.heroCaseStudy.map(cell => (
						<HeroCaseStudy title={cell.title} 
						subtitle={cell.subtitle}
						image={cell.image}/>
					))}
				</SectionHeroCaseStudy>
				
				<SectionIntroCaseStudy>
				{agorizeProductData.introCaseStudy3.map(cell => (
	  		 		 <IntroCaseStudy3 title={cell.title} 
	  		 		 description={cell.description} 
	  		 		 titleRight1={cell.titleRight1} 
	  		 		 descriptionRight1={cell.descriptionRight1}
	  		 		 titleRight2={cell.titleRight2} 
	  		 		 descriptionRight2={cell.descriptionRight2}
	  		 		 titleRight3={cell.titleRight3} 
	  		 		 descriptionRight3={cell.descriptionRight3}
	  		 		 />
	 			))}
 				</SectionIntroCaseStudy>
				
				<SectionTextBlockCentered>
				{agorizeProductData.textBlockCentered.map(cell => (
  		 		 <TextBlockCentered title={cell.title} paragraph={cell.paragraph} image={cell.image} caption={cell.caption} image2={cell.image2} caption2={cell.caption2} image3={cell.image3} caption3={cell.caption3} video={cell.video}/>
 				))}
				</SectionTextBlockCentered>

				{agorizeProductData.textBlock2col.map(cell => (
  		 		 <TextBlock2col
  		 		 	 title1={cell.title1} 
	  		 		 description1={cell.description1} 
	  		 		 description={cell.description} 
	  		 	 />
 				))}
			
				{agorizeProductData.largeImage.map(cell => (
					<LargeImage image={cell.image}></LargeImage>
				))}

				{agorizeProductData.textBlock2col2.map(cell => (
  		 		 <TextBlock2col
  		 		 	 title1={cell.title1} 
	  		 		 description1={cell.description1} 
	  		 		 description={cell.description} 
	  		 	 />
 				))}
			
				{agorizeProductData.largeImage2.map(cell => (
					<LargeImage image={cell.image}></LargeImage>
				))}

				{agorizeProductData.bigTitleCaseStudy.map(cell => (
	  		 	 <BigTitleCaseStudy 
	  		 	 	title={cell.title} 
	  		 		subtitle={cell.subtitle}
	  		 		image={cell.image}
	  		 	 />
	 			))}

				{agorizeProductData.textBlock2col3.map(cell => (
  		 		 <TextBlock2col
  		 		 	 title1={cell.title1} 
	  		 		 description1={cell.description1} 
	  		 		 description={cell.description} 
	  		 	 />
 				))}
			
				{agorizeProductData.largeImage3.map(cell => (
					<LargeImage image={cell.image}></LargeImage>
				))}


				{agorizeProductData.textBlock2col4.map(cell => (
  		 		 <TextBlock2col
  		 		 	 title1={cell.title1} 
	  		 		 description1={cell.description1} 
	  		 		 description={cell.description} 
	  		 	 />
 				))}

 				{agorizeProductData.largeImage4.map(cell => (
					<LargeImage image={cell.image}></LargeImage>
				))}


				{agorizeProductData.textBlock2col5.map(cell => (
  		 		 <TextBlock2col
  		 		 	 title1={cell.title1} 
	  		 		 description1={cell.description1} 
	  		 		 description={cell.description} 
	  		 	 />
 				))}

 				{agorizeProductData.largeImage5.map(cell => (
					<LargeImage image={cell.image}></LargeImage>
				))}

				{agorizeProductData.textBlock2col6.map(cell => (
  		 		 <TextBlock2col
  		 		 	 title1={cell.title1} 
	  		 		 description1={cell.description1} 
	  		 		 description={cell.description} 
	  		 	 />
 				))}

 				{agorizeProductData.largeImage6.map(cell => (
					<LargeImage image={cell.image}></LargeImage>
				))}
				
				<SectionTextBlockCentered>
				{agorizeProductData.textBlockCentered2.map(cell => (
  		 		 <TextBlockCentered title={cell.title} paragraph={cell.paragraph} image={cell.image} caption={cell.caption} image2={cell.image2} caption2={cell.caption2} image3={cell.image3} caption3={cell.caption3} video={cell.video}/>
 				))}
				</SectionTextBlockCentered>
				
				<div>
			    <FooterContainer>
			      <LeftContainer>
			        <PreviousLink>
			           <Link to="/sevenhugs-brand">← Previous project</Link>
			        </PreviousLink>
			      </LeftContainer>
			      <RightContainer>
			        <NextLink>
			          <Link to="/agorize-design-system">Next project→</Link>
			        </NextLink>
			      </RightContainer>
			    </FooterContainer>
  			</div>
				

			</div>
		</Layout>
		)
	}
}